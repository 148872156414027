import React, { useEffect } from "react";
import "./App.css";
import LandingPage from "./component/LandingPage/LandingPage";
function App() {
  useEffect(() => {
    if (process.env.REACT_APP_ENABLE_NEW_RELIC === "true") {
      const script = document.createElement("script");
      script.src = "/newrelic.js";
      script.async = true;
      document.head.appendChild(script);
    }
  }, []);

  return (
    <div className="body">
      <LandingPage />
    </div>
  );
}

export default App;
